
import {
    defineComponent, PropType
} from 'vue';
import { community } from '@/data';
import activityProp from '../activity-prop.vue';
import processing from './processing.vue';
import initPayApp from './init-pay-app';
import { PayUser } from './all.type';

export default defineComponent({
    components: {
        activityProp,
        processing
    },
    props: {
        mainId: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        payUser: {
            type: String as PropType<PayUser>,
            default: 'defaultUser'
        }
    },
    setup(props, { emit }) {
        const projectType = community.ProjectType.value;
        const {
            step,
            price,
            savePrice,
            activity,
            isAgree,
            createOrder,
            update,
            getPriceType,
            PriceType,
            getCreateOrderUrl,
            addAppFee,
            payJumpUrl
        } = initPayApp(
            props.payUser,
            props.mainId,
            props.id,
            projectType,
            emit
        );

        return {
            step,
            price,
            savePrice,
            activity,
            isAgree,
            createOrder,
            update,
            getPriceType,
            PriceType,
            getCreateOrderUrl,
            addAppFee,
            payJumpUrl
        };
    }
});
