import { community } from '@/data';
import noticeBase from '@/util/notice.base';

interface ReturnData {
    Active: 0|1;
    TipCode: number;
    msg?: string;
    mainId?: string;
    ID: string;
}

export default function createSubCallback(
    res: ReturnData,
    callback: Function,
    messageCallBack: () => void = () => true
) {
    if (res.TipCode === 0 || res.TipCode === 1 || res.TipCode === 2) {
        // 是额外从账号
        if (res.Active === 0) {
            // pay by no me
            if (community.ChargeMode.value === 1) {
                const tips = [
                    WordList.InstallerPmPayByNoMeMainEmail,
                    WordList.InstallerPmPayByNoMeMainPhone,
                    WordList.InstallerPmPayByNoMeMainNull
                ];
                noticeBase.messageBox(
                    'alert',
                    tips[res.TipCode],
                    WordList.PersonuserInfo,
                    'info'
                )(messageCallBack);
                return;
            }
            // pay by me
            callback('main');
            return;
        }
        // 非额外从账号
        const tips = [
            WordList.MainUserFillInEmail,
            WordList.MainUserFillInPhoneOnly,
            WordList.MainUserWithoutEmailAndPhone
        ];
        noticeBase.messageBox(
            'alert',
            tips[res.TipCode],
            WordList.Notice,
            'success'
        )(messageCallBack);
        return;
    }

    // 从账号填写邮箱和手机号
    if (res.Active === 0) {
        // pay by no me
        if (community.ChargeMode.value === 1) {
            noticeBase.messageBox(
                'alert',
                WordList.InstallerPmPayByNoMeSubEmailOrPhone,
                WordList.PersonuserInfo,
                'info'
            )(messageCallBack);
            return;
        }
        // pay by me
        callback('sub');
    } else {
        messageCallBack();
    }
}