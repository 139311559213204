import { ref, Ref } from 'vue';
import { ListHeaderItem } from '@/components/common/list';
import { isCNServer } from '@/util/location';

interface InfoData {
    Name: string;
    Email: string;
    Phone: string;
    Phone2: string;
    Phone3: string;
    PhoneCode: string;
}
const userDialogOperaType: Ref<OperateType> = ref('add');
const isShowTips = ref(false);

const initData = () => {
    const isShowFamilyMemberDialog = ref(false);
    const familyMemberDialogOperaType: Ref<OperateType> = ref('add');
    const headers: Ref<Array<ListHeaderItem>> = ref([]);
    if (isCNServer()) {
        headers.value = [{
            name: 'Name',
            label: WordList.TabelPersonUserInHtmlName
        }, {
            name: 'MobileNumber',
            label: WordList.ProperAllTextMobileNumber
        }, {
            name: 'CreateTime',
            label: WordList.TabelMessageBoxCreateTime
        }];
    } else {
        headers.value = [{
            name: 'Name',
            label: WordList.TabelPersonUserInHtmlName
        }, {
            name: 'Email',
            label: WordList.TabelPropertyManageEmail
        }, {
            name: 'MobileNumber',
            label: WordList.ProperAllTextMobileNumber
        }, {
            name: 'CreateTime',
            label: WordList.TabelMessageBoxCreateTime
        }];
    }
    return {
        isShowFamilyMemberDialog,
        familyMemberDialogOperaType,
        headers
    };
};
export default null;
export {
    InfoData,
    initData,
    userDialogOperaType,
    isShowTips
};