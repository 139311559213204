
import { defineComponent, ref, reactive } from 'vue';
import { account, community } from '@/data';
import addButton from '@/components/common/add-button/index.vue';
import {
    listPagination, RequestData, getSearch,
    ListActionItem
} from '@/components/common/list';
import payApp from '@/components/view/common/subscription/module/pay/pay-app.vue';
import familymemberDialog, {
    ResData
} from '@/components/view/installer/single/family-member-dialog';
import { tipsDialog as paymentTipsDialog } from '@/components/view/common/payment/index';
import { isCNServer } from '@/util/location';
import noticeBase from '@/util/notice.base';
import createSubCallback from '@/methods/createSubCallback';
import {
    InfoData, initData, userDialogOperaType, isShowTips
} from './fmemberutil';

export default defineComponent({
    components: {
        addButton,
        listPagination,
        payApp,
        familymemberDialog,
        paymentTipsDialog
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const userInfo = ref<InfoData>({
            Name: '',
            Email: '',
            Phone: '',
            Phone2: '',
            Phone3: '',
            PhoneCode: ''
        });
        const getPersonalInfoData = () => {
            account.getPersonalUserInfo(props.id, (res: InfoData) => {
                Object.keys(userInfo.value).forEach((item) => {
                    const key = item as keyof InfoData;
                    userInfo.value[key] = res[key];
                });
            });
        };
        getPersonalInfoData();
        const {
            isShowFamilyMemberDialog,
            familyMemberDialogOperaType,
            headers
        } = initData();
        const updateToList = ref(0);
        const listRequestData: RequestData = reactive({
            url: 'v3/web/single/user/getSubList',
            param: getSearch({
                ID: ''
            })
        });
        if (props.id) listRequestData.param.ID = props.id;

        const formData = ref({
            ID: props.id,
            Name: '',
            Email: '',
            PhoneCode: isCNServer() ? '86' : '',
            MobileNumber: '',
            Phone: '',
            MainUserEmail: '',
            Active: '0'
        });
        const addSubUser = () => {
            formData.value = {
                ID: props.id,
                Name: '',
                Email: '',
                PhoneCode: isCNServer() ? '86' : '',
                MobileNumber: '',
                Phone: '',
                MainUserEmail: '',
                Active: '0'
            };
            userDialogOperaType.value = 'add';
            isShowFamilyMemberDialog.value = true;
        };
        const edit = (listItem: FormData) => {
            const obj = JSON.parse(JSON.stringify(listItem));
            formData.value = {
                ID: obj.ID,
                Name: obj.Name,
                Email: obj.Email,
                PhoneCode: obj.PhoneCode,
                MobileNumber: obj.MobileNumber,
                Phone: obj.Phone,
                MainUserEmail: userInfo.value.Email,
                Active: obj.Active
            };
            userDialogOperaType.value = 'edit';
            isShowFamilyMemberDialog.value = true;
        };

        const payUserID = ref('');
        const mainID = ref('');
        const isShowPay = ref(false);
        const action: ListActionItem[] = [{
            type: 'pay',
            event(val: { ID: string }) {
                if (community.ChargeMode.value === 1) {
                    noticeBase.messageBox(
                        'alert',
                        WordList.AdditionalAppPermissionsNotice,
                        WordList.PersonuserInfo,
                        'info'
                    )(() => true);
                    return;
                }
                payUserID.value = val.ID;
                mainID.value = props.id;
                isShowPay.value = true;
                // window.open(val.bmurl);
            },
            class: 'el-icon-my-pay modify-icon',
            showCondition(val: { Active: string }) {
                return val.Active === '0';
            },
            title: WordList.OrderPay
        }];
        const isShowPayNow = () => {
            updateToList.value += 1;
            isShowTips.value = false;
            isShowPay.value = true;
        };

        const noticeType = ref('');
        const noticeMainCode = ref();
        const addFamilyMember = (res: ResData) => {
            isShowTips.value = false;
            updateToList.value += 1;
            createSubCallback(
                res,
                (type: 'main'|'sub'|'') => {
                    noticeMainCode.value = res.TipCode;
                    noticeType.value = type;
                    mainID.value = props.id;
                    payUserID.value = res.ID;
                    isShowTips.value = true;
                }
            );
            isShowFamilyMemberDialog.value = false;
        };

        return {
            getPersonalInfoData,
            userInfo,
            isShowFamilyMemberDialog,
            familyMemberDialogOperaType,
            headers,
            updateToList,
            listRequestData,
            action,
            isShowPay,
            payUserID,
            mainID,
            addSubUser,
            formData,
            edit,
            userDialogOperaType,
            isShowTips,
            isShowPayNow,
            addFamilyMember,
            noticeType,
            noticeMainCode
        };
    }
});
