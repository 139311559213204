import { reactive, ref } from 'vue';
import { order, orderType } from '@/data';
import HttpRequest from '@/util/axios.config';
import { subscriptionApi } from '@/api';
import { PayUser } from './all.type';

const getPriceType = (type: PayUser) => ({
    installer: WordList.ProperAllTextSubscriptionDuration,
    defaultUser: WordList.OrderTotalPrice,
    community: WordList.ProperAllTextSubscriptionDuration
}[type]);

const getCreateOrderUrl = (type: PayUser) => ({
    installer: 'v3/web/single/order/create',
    defaultUser: 'v3/web/community/order/create',
    community: 'v3/web/community/order/create'
}[type]);

const initPayApp = (
    payUser: PayUser,
    mainId: string,
    id: string,
    projectType: string,
    emit: any
) => {
    const step = ref(1);
    const price = ref(0);
    const savePrice = ref(0);
    const activity: Array<string> = reactive([]);
    const isAgree = ref(false);
    const addAppFee = ref(0);
    const PriceType = getPriceType(payUser);

    const callback = (data: orderType.EndUserCharge) => {
        price.value = Number(data.money);
        addAppFee.value = Number(data.addAppFee);
        if (data.Introduction) activity.push(data.Introduction);
        savePrice.value = Number(data.AddAppSaveFee);
    };

    if (payUser === 'defaultUser') {
        subscriptionApi.getPMUserChargePlan({ ID: mainId }, callback);
    } else if (payUser === 'installer' || payUser === 'community') {
        if (projectType === 'community') {
            subscriptionApi.getCommunitySubUserPay({ ID: mainId }, callback);
        } else if (projectType === 'single') {
            subscriptionApi.getSingleSubUserPay({ ID: mainId }, callback);
        }
    }

    const payJumpUrl = ref('');
    const createOrder = () => {
        HttpRequest.post(getCreateOrderUrl(payUser), {
            Type: 2,
            Total: price.value,
            Users: JSON.stringify([id])
        }, [(data: { bmurl: string }) => {
            step.value = 2;
            payJumpUrl.value = data.bmurl;
            window.open(data.bmurl);
        }, false]);
    };

    const update = () => {
        emit('success');
        emit('close');
    };

    return {
        step,
        price,
        savePrice,
        activity,
        isAgree,
        createOrder,
        update,
        getPriceType,
        PriceType,
        getCreateOrderUrl,
        addAppFee,
        payJumpUrl
    };
};

export default initPayApp;
export {
    getPriceType,
    getCreateOrderUrl
};
