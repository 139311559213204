
import {
    defineComponent,
    PropType,
    reactive,
    ref,
    Ref
} from 'vue';
import dialogShell from '@/components/common/dialog-shell/index.vue';
import { checkSpecialCharacters } from '@/methods/rules/device';
import { phoneCode, timeZone, community } from '@/data';
import { isCNServer } from '@/util/location';
import { user } from '@/methods/rule';
import { residentDeviceApi, userForSingleApi } from '@/api';
import noticeBase from '@/util/notice.base';
import { isNew } from '@/data/src/community';
import { getPhoneCodeOps } from '@/util/phone-code';
import { SubFormData, ResData } from './all.type';

const { timeZoneOptions } = timeZone;
// const space = ' ';
const rules = {
    Name: [{
        required: true,
        message: WordList.RuleNameEmpty,
        trigger: 'blur'
    }, {
        validator: user.checkManageAccount,
        trigger: 'blur'
    }],
    FirstName: [{
        required: true,
        message: WordList.RuleNameEmpty,
        trigger: 'blur'
    }, {
        validator: checkSpecialCharacters,
        trigger: 'blur'
    }],
    LastName: [{
        required: true,
        message: WordList.RuleNameEmpty,
        trigger: 'blur'
    }, {
        validator: checkSpecialCharacters,
        trigger: 'blur'
    }],
    Email: [{
        required: true,
        message: WordList.RuleEmailEmpty,
        trigger: 'blur'
    }, {
        validator: user.checkEmail,
        trigger: 'blur'
    }],
    MobileNumber: [{
        required: true,
        message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextMobileNumber),
        trigger: 'blur'
    }]
};

const title = {
    community: WordList.NavInHtmlMenuUser2,
    single: WordList.NavInHtmlMenuUser2
};

export default defineComponent({
    emits: ['close', 'submit', 'success'],
    components: {
        dialogShell
    },
    props: {
        operaType: {
            type: String as PropType<OperateType>,
            required: true
        },
        initForm: {
            type: Object as PropType<SubFormData>,
            required: true
        },
        identity: {
            type: String as PropType<'single' | 'community'>,
            default: 'single'
        },
        details: {
            type: Object
        }
    },
    setup(props, { emit }) {
        const urls = {
            single: {
                add: userForSingleApi.addSubUser,
                edit: userForSingleApi.editSubUser
            },
            community: {
                add: residentDeviceApi.addSubUser,
                edit: residentDeviceApi.editSubUser
            }
        };
        const formRef: Ref<any> = ref(null);

        const formData = reactive({ ...props.initForm });
        if (isCNServer()) {
            formData.PhoneCode = '86';
        }
        // 楼层数据
        // 楼层数据
        const floorAreaOptions = Array.from(Array(128).keys(), (n) => ({ value: `${n + 1}`, label: `${n + 1}` }));
        const accessibleFloorOptions = [{ value: 'all', label: WordList.AllFloors }].concat(floorAreaOptions);
        // v6.4，modify by lxf，从账号已经填了邮箱，邮箱就为必填项
        formData.Email = formData.Email || '';
        formData.MainUserEmail = formData.MainUserEmail || '';
        rules.Email = formData.Email === '' ? [{ validator: user.checkEmail, trigger: 'blur' }] : [{
            required: true,
            message: WordList.RuleEmailEmpty,
            trigger: 'blur'
        }, {
            validator: user.checkEmail,
            trigger: 'blur'
        }];
        rules.MobileNumber = !formData.MobileNumber || formData.MobileNumber === '' ? [] : [{
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextMobileNumber),
            trigger: 'blur'
        }];
        // acc楼层多选限制数量
        const multipleLimitAccFloor = ref('10');
        function multipleAccFloorChange(val: string) {
            if (formData.AccessFloor.length > 0 && (formData.AccessFloor.length > val.length)) {
                return;
            }
            if (val.includes('all') && val.length === 1) {
                multipleLimitAccFloor.value = '1';
            } else if (val.includes('all') && val.length > 1) {
                multipleLimitAccFloor.value = '1';
                formData.AccessFloor = ['all'];
            } else {
                multipleLimitAccFloor.value = '10';
            }
        }
        // 更新AccessFloor
        function updateAccessFloor(val: string[]) {
            if (formData.AccessFloor.length > 0 && (formData.AccessFloor.length > val.length)) {
                return;
            }
            formData.AccessFloor = val;
        }
        // 删除AccessFloor tag
        function removeTag(val: string) {
            const index = formData.AccessFloor.findIndex((item) => item === val);
            if (index > -1) {
                formData.AccessFloor = formData.AccessFloor.filter((item, fIndex) => fIndex !== index);
            }
        }
        const submit = () => {
            formRef.value.validate((valid: boolean) => {
                if (!valid) return false;
                const { AccessFloor, ...other } = formData;
                const AccessFloorTemp = isNew.value && AccessFloor ? AccessFloor.join(';') : '';
                const param = AccessFloor ? { ...other, AccessFloor: AccessFloorTemp } : { ...other };
                urls[props.identity][props.operaType](param, [(res: ResData) => {
                    emit('success', res);
                }, false]);
                return true;
            });
        };

        const showLandLine = ref(true);
        if (props.identity === 'community' && community.IsEnableLandline.value !== 1) {
            showLandLine.value = false;
        }

        const limitMobileNumberType = (val: string) => {
            const reg = /[^\d]]*/g;
            formData.MobileNumber = val.replace(reg, '');
        };

        // 编辑时显示reset password按钮
        const isShowResetBtn = ref(false);
        const isDisabledResetBtn = ref(true);
        if (props.operaType === 'edit') {
            isShowResetBtn.value = true;
            // 主账号\从账号有填邮箱
            if (formData.MainUserEmail !== '' || formData.Email !== '') {
                isDisabledResetBtn.value = false;
            }
        }
        const ResetPw = () => {
            const api = props.identity === 'single' ? userForSingleApi.resetPassword : residentDeviceApi.resetPasswd;
            api({
                ID: formData.ID
            }, [(data: { msg: string }) => {
                const alertMsg = formData.Email === '' ? WordList.ResetSubUserWithoutEmail : data.msg;
                noticeBase.messageBox('alert', alertMsg, WordList.TabelChangePwStepSuccess, 'success')(() => false);
            }, false]);
        };

        return {
            formData,
            langOptions,
            rules,
            timeZoneOptions,
            phoneCode,
            isCNServer: isCNServer(),
            formRef,
            submit,
            title,
            community,
            showLandLine,
            limitMobileNumberType,
            isShowResetBtn,
            ResetPw,
            isDisabledResetBtn,
            accessibleFloorOptions,
            multipleAccFloorChange,
            multipleLimitAccFloor,
            isNew,
            updateAccessFloor,
            removeTag,
            getPhoneCodeOps
        };
    }
});
